
var lightcaseLabels = ($("body.lang-de").length) ? {
    'errorMessage': 'Quelle nicht gefunden',
    'sequenceInfo.of': ' von ',
    'close': 'Schließen',
    'navigator.prev': 'Zurück',
    'navigator.next': 'Weiter',
    'navigator.play': 'Start',
    'navigator.pause': 'Pause'
} : undefined;

$(document).ready(function()
{

	var $body = $('body');

	$body.backToTop();

	/**
	 * BODY CLASS HANDLING
	 */
	$body.removeClass('nojs').addClass('javascript').placeholderFix();

	/**
	 * ESCAPE THE MAILMASK LINKS
	 */
	$('a.escape').noSpam();

	/**
	 * FORM VALIDATION AND SUBMIT VIA AJAX
	 */
	$('form[data-validate="true"]').each(function()
	{
		$(this).validate();
	});

	$('.slideshow').showslide();

    /**
     * CALL COLORBOX FOR IMAGES
     */

    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'image'
    });

	/**
	 * DETECT EXTERNAL LINKS AND ADD target=_blank
	 */
	$.expr[':'].external = function(obj){return !obj.href.match(/^mailto\:/) && !obj.href.match(/^javascript\:/) && (obj.hostname != location.hostname) && ('www.' + obj.hostname != location.hostname);};
	$('a:external').attr('target', '_blank');


	// Navi
	var direction;
	var directionScroll = $(window).scrollTop();
    $('.page-navi').addClass('active');

	var headerHeight = $('header').height();
	$(window).scroll(function(){

		// Navi Scroll

		var directionScroll2 = $(window).scrollTop();
        if(directionScroll < directionScroll2 && directionScroll2 > 0 && directionScroll2 > 50)
	    {
				$('.page-navi').removeClass('active');
        }
        else
        {
			$('.page-navi').addClass('active');
        }
        directionScroll = directionScroll2;



		var scrollTop = $(window).scrollTop();
		if((headerHeight-50) < scrollTop){
			$('.iconMenu').addClass('active');
		}
		else{			
			$('.iconMenu').removeClass('active');
		}

	});

	// DateDropper
	$('input.dateDropper').dateDropper();

	if(!$('body').hasClass('index')){
		setTimeout(function(){
			$('html,body').animate({scrollTop: $('main').offset().top},250);
		},500);
	}

	

	/*
	 * Parallax SCROLL mit Bestimmung der Höhe | EACH CONTENT
	 * @author Christoph Roderer
	 * @version 1.0
	 * @category jQuery plugin
	*/

	$(window).scroll(function(){

		//Standards
		var scrollTop = $(window).scrollTop();
		var winHeight = $(window).height();

		$('.parallax').each(function(){
			
			// SETTINGS
			var area = 1; 	// 0.0 - 1.0 um den Sichtbereich zu bestimmen

			var boxHeight = $(this).height();
			var uspTop = $(this).offset().top;
			var uspImgHeight = $(this).children('.parallaxImg').height();

			//Berechnung
			var scrollBox = scrollTop - (uspTop - winHeight);
			var rightHeight = winHeight + boxHeight;
			var scrollPro = (100 / rightHeight)*scrollBox;
			var scrollPro = scrollPro*(-1);

			var scrollSize = uspImgHeight - boxHeight;
			var boxScroll = scrollSize / 100 ;
			var scrollHeight = (boxScroll * scrollPro) * area ;
			$(this).children('.parallaxImg').css({bottom : scrollHeight + "px"});
		});
	});

	$('a[href*="/kontakt/"]').pageForm({debug: true});

	loadImages();
	// if()
	// {
	// 	console.log('inside the function');
	// }
	// loadImages();

	$('.gaoptout').gaOptout();
	$('.gaoptin').gaOptin();

});

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
